<template>
    <div class="container">
        <h2 class="border-bottom pb-2 mb-3">
            <i class="fa fa-heartbeat"></i> Health Check {{ cluster_ip ? `- ${cluster_ip}` : ""}}
            <span v-if="cluster_ip" style="float: right;cursor: pointer;" @click="cluster_ip = null;"><i class="fa fa-close"></i></span>
        </h2>

        <div class="row mb-3">
            <div class="col-4">
                <div class="card">
                    <div class="card-body text-center">
                        <h4>{{ stats.active || 0 }} Active</h4>
                    </div>
                </div>
            </div>

            <div class="col-4">
                <div class="card">
                    <div class="card-body text-center">
                        <h4>{{ stats.failed || 0 }} Failed</h4>
                    </div>
                </div>
            </div>

            <div class="col-4">
                <div class="card">
                    <div class="card-body text-center">
                        <h4>{{ stats.total || 0 }} Total</h4>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-danger text-white p-2 mb-3" v-if="stats.stalled">
            <h6 class="m-0"><i class="fa fa-warning"></i> You have {{ stats.stalled }} devices that needs your attention</h6>
        </div>

        <div v-if="!cluster_ip" class="row mb-2">
            <div class="col-12">
                <div class="d-flex" style="float: right;">
                    <div class="mr-1">
                        <button class="btn btn-small btn-primary" type="button" @click="item = { credentials: {host: {}, switch: {}}}">New Cluster</button>
                    </div>
                    <div>
                        <button class="btn btn-small btn-secondary" type="button" data-toggle='modal'
                                data-target='#myAuditModal'>Cluster Logs</button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!cluster_ip" class="row mb-3" style="background-color: white; margin: 2px">
            <div class="col-12">
                <b-table :fields="columnFields" :items="clusters">

                    <template v-slot:cell(status)="row">
                        <span class="text-white" :class="row.item.status === 'active' ? 'badge badge-success': 'badge badge-danger'">{{ row.item.status }}</span>
                    </template>

                    <template v-slot:cell(devices)="row">
                        <p class="d-flex align-content-center">
                            {{ row.item.devices ? row.item.devices.active : 0 }} Healthy out of {{ row.item.devices ? row.item.devices.total : 0 }} Devices
                        </p>
                    </template>

                    <template v-slot:cell(stalled)="row">
                        <span v-if="row.item.poe === 'yes'">
                            <div class="spinner-border text-danger" style="width: 1rem; height: 1rem;" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                        </span>

                        <span v-if="row.item.discover === 'yes'">
                            <div class="spinner-border text-primary" style="width: 1rem; height: 1rem;" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                        </span>

                        <span class="p-1" v-if="row.item.devices && row.item.devices.stalled">
                            <i class="fa fa-warning text-danger"></i>
                            <span class="badge badge-light">{{ row.item.devices ? row.item.devices.stalled : 0 }}</span>
                        </span>

                        <span class="p-1" v-if="row.item.devices && row.item.devices.failed">
                            <i class="fa fa-warning text-warning"></i>
                            <span class="badge badge-light">{{ row.item.devices ? row.item.devices.failed : 0 }}</span>
                        </span>
                    </template>

                    <template v-slot:cell(checked_ts)="row">
                        <p class="d-flex align-content-center">
                            {{ new Date(row.item.checked_ts).toLocaleString() }}
                        </p>
                    </template>

                    <template v-slot:cell(actions)="row">
                        <div class="btn-group dropright">
                            <a style="cursor: pointer;" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                More
                            </a>
                            <div class="dropdown-menu">
                                <button class="dropdown-item" type="button" @click="cluster_ip = row.item.ip;">View</button>
                                <button class="dropdown-item" type="button" @click="item = row.item;">Edit</button>
                                <button class="dropdown-item" type="button" @click="log = row.item;">Logs</button>
                                <button class="dropdown-item" type="button" @click="discoverCluster(row.item)">Discover</button>
                                <hr class="m-0" />
                                <button class="dropdown-item text-danger" type="button" @click="stopCluster(row.item)">Stop Cluster</button>
                                <button class="dropdown-item text-success" type="button" @click="startCluster(row.item)">Start Cluster</button>
                                <button class="dropdown-item text-danger" type="button" v-if="row.item.type === 'pi'" @click="poeCluster(row.item)">Poe Cluster</button>
                            </div>
                        </div>
                    </template>

                </b-table>
            </div>
        </div>

        <div v-if="cluster_ip" class="row mb-3">
            <devices :ip="cluster_ip" :event="events"></devices>
        </div>
        <edit v-if="item" :item="item" v-on:close="item = null" v-on:openclose="item = null;"/>
        <logs v-if="log" :id="log" type="cluster"  @close="log = null;"/>
        <audit></audit>
    </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import EventSource from "eventsource";
import Edit from "@/components/cluster/Edit";
import Devices from "@/components/cluster/Devices";
import Logs from "@/components/cluster/Logs";
import Audit from "@/components/cluster/Audit";

export default {
    name: "Device",
    props: ["user"],
    components: {Edit, Devices, Logs, Audit},
    computed: {
        stats() {
            let stat = { active: 0, failed: 0, stalled: 0, total: 0 };

            if (!this.cluster_ip) {
                for(let i = 0; i < this.clusters.length; i++) {
                    stat.active = stat.active + (this.clusters[i].devices ? this.clusters[i].devices.active : 0);
                    stat.failed = stat.failed + (this.clusters[i].devices ? this.clusters[i].devices.failed : 0);
                    stat.stalled = stat.stalled + (this.clusters[i].devices ? this.clusters[i].devices.stalled : 0);
                    stat.total = stat.total + (this.clusters[i].devices ? this.clusters[i].devices.total : 0);
                }
            } else {
                for(let i = 0; i < this.clusters.length; i++) {
                    if(this.clusters[i].ip === this.cluster_ip) {
                        stat.active = stat.active + (this.clusters[i].devices ? this.clusters[i].devices.active : 0);
                        stat.failed = stat.failed + (this.clusters[i].devices ? this.clusters[i].devices.failed : 0);
                        stat.stalled = stat.stalled + (this.clusters[i].devices ? this.clusters[i].devices.stalled : 0);
                        stat.total = stat.total + (this.clusters[i].devices ? this.clusters[i].devices.total : 0);
                    }
                }
            }
            return stat
        },
        columnFields() {
            return [
                {
                    key: 'ip',
                    label: 'Address',
                    sortable: false
                },
                {
                    key: 'type',
                    label: 'Type',
                    sortable: false
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: false
                },
                {
                    key: 'devices',
                    label: '',
                    sortable: false
                },
                {
                    key: 'stalled',
                    label: '',
                    sortable: false
                },
                {
                    key: 'checked_ts',
                    label: 'Last Checked',
                    sortable: false
                },
                {
                    key: 'actions',
                    label: '',
                    sortable: false
                },
            ]
        }
    },
    data: function () {
        return {
            clusters: [],
            cluster_ip: null,
            cluster: null,
            item: null,
            log: null,
            events: null,
        };
    },
    created: function () {
        this.$root.preloader = false;
    },
    mounted() {
        this.getDevices();
        this.events = new EventSource(
            `${this.$root.serverUrl}/admin/cluster/subscribe`,
            { headers: { 'Authorization': "Bearer " +  this.$cookies.get('accessToken')} }
        );
        this.events.onmessage = (event) => {
            const parsedData = JSON.parse(event.data);
            if(parsedData.type === "cluster") this.clusters = parsedData.result;
        };
    },
    methods: {
        poeCluster(cluster) {
            let req = {_id: cluster._id};
            axios.post(`${this.$root.serverUrl}/admin/cluster/poe`, req).then(() => {
                this.$bvToast.toast("Please wait while your cluster is restarted.", {
                    title: <strong>Processing</strong>,
                    variant: 'success',
                    solid: true
                });
            }).catch((err) => {
                console.log(`Fetch error: ${err.message}`, err.stack);
                swal.fire({title: "Error", text: err.message, icon: "error"});
            });
        },
        discoverCluster(cluster) {
            let req = {_id: cluster._id};
            axios.post(`${this.$root.serverUrl}/admin/cluster/discover`, req).then(() => {
                this.$bvToast.toast("Please wait while your cluster is been scanned.", {
                    title: <strong>Processing</strong>,
                    variant: 'success',
                    solid: true
                });
            }).catch((err) => {
                console.log(`Fetch error: ${err.message}`, err.stack);
                swal.fire({title: "Error", text: err.message, icon: "error"});
            });
        },
        stopCluster(cluster) {
            swal({title:"Stop Cluster", text:"Are you sure", icon:"info", buttons:['No','Yes']}).then((val) => {
                if (!val) return;

                let req = {_id: cluster._id};
                axios.put(`${this.$root.serverUrl}/admin/cluster/stop`, req).then((resp) => {
                    this.$bvToast.toast("Cluster stopped successfully", {
                        title: <strong>Successful</strong>,
                        variant: 'success',
                        solid: true
                    });
                    console.log(resp)
                }).catch((err) => {
                    console.log(`Fetch error: ${err.message}`, err.stack);
                    swal.fire({title: "Error", text: err.message, icon: "error"});
                });
            })
        },
        startCluster(cluster) {
            swal({title:"Start Cluster", text:"Are you sure", icon:"info", buttons:['No','Yes']}).then((val) => {
                if (!val) return;

                let req = {_id: cluster._id};
                axios.put(`${this.$root.serverUrl}/admin/cluster/start`, req).then((resp) => {
                    this.$bvToast.toast("Cluster started successfully", {
                        title: <strong>Successful</strong>,
                        variant: 'success',
                        solid: true
                    });
                    console.log(resp)
                }).catch((err) => {
                    console.log(`Fetch error: ${err.message}`, err.stack);
                    swal.fire({title: "Error", text: err.message, icon: "error"});
                });
            })
        },
        getDevices() {
            axios.get(`${this.$root.serverUrl}/admin/cluster`).then((resp) => {
                this.clusters = resp.data;
            }).catch((err) => {
                console.log(`Fetch error: ${err.message}`, err.stack);
                swal.fire({ title: "Error", text: err.message, icon: "error" });
            });
        }
    },
};
</script>

<style scoped>

</style>
