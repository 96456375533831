<template>
    <!-- The Modal -->
    <div class="modal fade" id="myAuditModal">
        <div class="modal-dialog modal-lg">
            <div v-if="loading" class="loader-body">
                <div class="loader"></div>
            </div>

            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Cluster Audit</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">

                    <div class="row">
                        <div class="col-sm-4 col-md-3 col-lg-2">
                            <div class="form-group">
                                <h5>Date Range</h5>
                                <div class="wrap">
                                    <ejs-daterangepicker cssClass="e-outline" v-model="filters.date"></ejs-daterangepicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2">
                            <div class="tabs-wrap" style="padding-top: 37px;">
                                <button class="e-control e-progress-btn e-lib e-btn e-spin-right" data-ripple="true" aria-label="SEARCH progress" style="color: white; background-color: rgb(56, 56, 56);" @click="search">
                                    <span class="e-btn-content">SEARCH</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <ejs-grid ref="lgrid" :dataSource="data" :allowPaging="true" :allowSorting="true" :pageSettings="pageSettings">
                        <e-columns>
                            <e-column field="date" headerText="Date" text-Align="Left" width="200" :format="{type: 'dateTime', skeleton: 'medium'}"></e-column>
                            <e-column field="user" headerText="User" text-Align="Left" width="200"></e-column>
                            <e-column field="success" headerText="Success" text-Align="Left" width="200"></e-column>
                            <e-column field="message" headerText="Error Message" text-Align="Left" width="200"></e-column>
                        </e-columns>
                    </ejs-grid>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import Vue from "vue";
import {GridPlugin, Page, Sort} from "@syncfusion/ej2-vue-grids";
import swal from "sweetalert";
import {DataManager, WebApiAdaptor} from "@syncfusion/ej2-data";
import moment from "moment/moment";


Vue.use(GridPlugin);

export default {
    name: "ClusterAuditModal",
    provide: {
        grid: [Page, Sort]
    },
    data() {
        return {
            temp: null,
            loading: false,
            data: [],
            filters: {
                date: {
                    start: moment().startOf('day').toDate(),
                    end: moment().endOf('day').toDate()
                }
            },
            pageSettings: { pageSize: 20, pageSizes: true },
            preloader: false,
            spinSettings: { duration: 10000000, position: 'Right', width: 20, template: '<div class="template"></div>' },
        }
    },
    methods: {
        search: async function () {
            let request = {
                sSearch: null,
                filters: {}
            };
            if (this.filters.start) request.filters.start = this.filters.start;
            if (this.filters.end) request.filters.end = this.filters.end;
            if (this.filters.type) request.filters.product_type = this.filters.type;
            if (this.filters.client) request.filters.client_id = this.filters.client;
            if (this.filters.product_type) request.filters.product_type = this.filters.product_type;
            if (this.filters.hourly) request.filters.hourly = this.filters.hourly;

            this.data = null;
            this.hourly = this.filters.hourly;

            request.page = this.page;
            request.max = this.max;

            let query = [];
            for (var key in request) {
                if (request.hasOwnProperty(key)) {
                    if (request[key]) {
                        query.push(`${key}=${typeof request[key] === 'object' ? JSON.stringify(request[key]) : request[key]}`);
                    }
                }
            }

            // this.loading = true;

            this.$nextTick(async () => {
                try {
                    this.data = await new DataManager({
                        url: `${this.$root.serverUrl}/admin/cluster/audit?${query.join("&")}`,
                        adaptor: new WebApiAdaptor(),
                        crossDomain: true,
                        headers:[{ 'Authorization': 'Bearer ' + this.$cookies.get('accessToken') }]
                    })
                } catch (err) {
                    swal({ title: "Oops", text: err.message, icon: "error" });
                }
            })
        },
    }
};
</script>

<style scoped>
.modal-dialog {
    max-width: 90%;
    height: 90vh;
    display: flex;
    max-width: 1620px;
}
.modal-header {
    background: #0d0d48;
}
.modal-title {
    color: #FFFFFF;
}
.close {
    color: #FFFFFF;
}
.campaign-container {
    gap: 40px;
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.modal-body {
    overflow: scroll;
}
.campaign-creative {
    flex: 1 0;
    border: 5px solid grey;
    background: grey;
    height: 100%;
    overflow: scroll;
}
/*.campaign-details {*/
/*  width: 50%;*/
/*}*/
.vrl {
    height: 200px;
    border-left: 1px solid grey;
}
.info-sec {
    display: flex;
    align-items: center;
    gap: 40px;
}
.info-sec i {
    font-size: 100px;
}
.mx-content {
    overflow: scroll;
    height: 100%;
}
.custom-row-class {
    background-color: red;
    color: #FFFFFF;
}
div.unsub-bg {
    background: rgba(255, 0, 0, 1);
}
.legend-cat {
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
    row-gap: 10px;
}
.loader-body {
    position: absolute;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 9999;
    background: #000000ad;
}

.loader {
    border: 8px solid #f3f3f3; /* Light gray border */
    border-top: 8px solid #3498db; /* Blue border */
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
