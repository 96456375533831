<template>
    <div class="col-12">
        <div v-for="(item, index) in cluster_group" :key="index">
            <div class="p-1" style="background-color: grey; color: white;" v-if="item[0].switch">Switch - {{ item[0].switch.switch_ip }}</div>
            <b-table style="background-color: white;" :fields="clusterFields" :items="item">
                <template v-slot:cell(status)="row">
                    <span class="text-white badge badge-success" v-if="row.item.status === 'active'">{{ row.item.status }}</span>
                    <span class="text-white badge badge-warning" v-if="row.item.status === 'failed'">{{ row.item.status }}</span>
                    <span class="text-white badge badge-danger" v-if="row.item.status === 'stalled'">{{ row.item.status }}</span>
                </template>

                <template v-slot:cell(workers)="row">
                    <p>{{ row.item.workers || 0 }} of {{ row.item.max || 0 }}</p>
                </template>

                <template v-slot:cell(date)="row">
                    <p>{{ row.item.date ? timeSince(new Date(row.item.date)) : "N/A" }}</p>
                </template>

                <template v-slot:cell(switch)="row">
                    <p>{{ row.item.switch ? row.item.switch.switch_port : row.item.container ? row.item.container.name : "" }}</p>
                </template>

                <template v-slot:cell(checks)="row">
                    <div v-if="row.item.checks">
                        <span class="text-white badge badge-success" v-if="row.item.status === 'active'">
                            <i class="fa fa-check"></i>
                        </span>
                        <span class="text-white badge badge-danger" v-if="row.item.status === 'failed'">
                            <i class="fa fa-close"></i>
                        </span>
                        <span class="text-white badge badge-warning" v-if="row.item.status === 'warning'">
                            <i class="fa fa-warning"></i>
                        </span>

                        <span class="ml-2">{{ row.item.checks || 0 }}/{{ row.item.max_checks || 0 }} checks</span>
                        <div v-if="row.item.last_check"><small style="margin-left: 20px;">{{ timeSince(new Date(row.item.last_check)) }} ago</small></div>
                    </div>
                </template>

                <template v-slot:cell(actions)="row">
                    <div class="btn-group dropright">
                        <a class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            More
                        </a>
                        <div class="dropdown-menu">
                            <button class="dropdown-item" type="button" @click="log = row.item;">Logs</button>
                            <button class="dropdown-item" type="button" @click="reboot(row.item)">Reboot</button>
                        </div>
                    </div>
                </template>

            </b-table>
        </div>
        <logs v-if="log" :id="log" type="worker"  @close="log = null;"/>
    </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import Logs from "@/components/cluster/Logs";

export default {
    name: "edit-cluster",
    computed: {
        cluster_group() {
            let list = this.clusters.reduce((acc, value) => {
                // Group host and switches
                if (value.switch) {
                    if (!acc[value.cluster_ip]) {
                        acc[value.cluster_ip] = {[value.switch.switch_ip]: [value]};
                    } else {
                        if(acc[value.cluster_ip][value.switch.switch_ip]) acc[value.cluster_ip][value.switch.switch_ip].push(value)
                        else acc[value.cluster_ip][value.switch.switch_ip] = [value];
                    }
                } else if (value.container) {
                    if (!acc[value.cluster_ip]) {
                        acc[value.cluster_ip] = {"container": [value]};
                    } else {
                        if(acc[value.cluster_ip]["container"]) acc[value.cluster_ip]["container"].push(value)
                    }
                }

                return acc;
            }, {});
            let group = [];

            let clusters = Object.keys(list);
            for(let c in clusters) {
                let switches = Object.keys(list[clusters[c]]);
                for (let s in switches) {
                    group.push(list[clusters[c]][switches[s]])
                }
            }
            return group;
        },
        clusterFields() {
            return [
                {
                    key: 'ip',
                    label: 'Address',
                    sortable: false
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: false
                },
                {
                    key: 'workers',
                    label: 'Utilization',
                    sortable: false
                },
                {
                    key: 'date',
                    label: 'Uptime',
                    sortable: false
                },
                {
                    key: 'switch',
                    label: 'Port / Name',
                    sortable: false
                },
                {
                    key: 'checks',
                    label: '',
                    sortable: false
                },
                {
                    key: 'actions',
                    label: '',
                    sortable: false
                },
            ]
        },
    },
    data() {
        return {
            clusters: [],
            log: null
        }
    },
    mounted() {
        this.getWorkers();
        this.event.onmessage = (event) => {
            const parsedData = JSON.parse(event.data);
            console.log(parsedData);
            if(parsedData.type === "worker") this.updateWorkers(parsedData.workers);
        };
    },
    props: {
        ip: {
            type: String,
            required: true,
        },
        event: {
            type: Object,
            required: true,
        },
    },
    components: {Logs},
    methods: {
        updateWorkers(workers) {
            this.clusters = this.clusters.map((cluster) => {
                for(let i = 0; i < workers.length; i++) {
                    let worker = workers[i];
                    if(cluster._id == worker._id) {
                        cluster = worker;
                        break;
                    }
                }
                return cluster;
            })
        },
        timeSince(date) {
            var seconds = Math.floor((new Date() - date) / 1000);

            var interval = seconds / 31536000;

            if (interval > 1) {
                return Math.floor(interval) + " years";
            }
            interval = seconds / 2592000;
            if (interval > 1) {
                return Math.floor(interval) + " months";
            }
            interval = seconds / 86400;
            if (interval > 1) {
                return Math.floor(interval) + " days";
            }
            interval = seconds / 3600;
            if (interval > 1) {
                return Math.floor(interval) + " hours";
            }
            interval = seconds / 60;
            if (interval > 1) {
                return Math.floor(interval) + " minutes";
            }
            return Math.floor(seconds) + " seconds";
        },
        reboot(port) {
            swal({title:"Reboot", text:"Are you sure", icon:"info", buttons:['No','Yes']}).then((val) => {
                if (!val) return;

                if (port.switch) port.switch.mac = port.mac;
                if (port.container) port.container.type = port.type;
                let req = {worker_id: port._id, cluster_ip: port.cluster_ip, port: port.switch ? port.switch : port.container};
                axios.post(`${this.$root.serverUrl}/admin/cluster/restart/device`, req).then((resp) => {
                    this.$bvToast.toast("Device reboot request sent", {
                        title: <strong>Successful</strong>,
                        variant: 'success',
                        solid: true
                    });
                    console.log(resp);
                }).catch((err) => {
                    console.log(`Fetch error: ${err.message}`, err.stack);
                    swal.fire({title: "Error", text: err.message, icon: "error"});
                });
            });
        },
        save() {
            let action = this.cluster._id ? "put" : "post";
            axios[action](`${this.$root.serverUrl}/admin/cluster`, this.cluster).then((resp) => {
                if ( resp.data && resp.data.error ) {
                    this.$bvToast.toast(resp.data.error.message, {
                        title: <strong>Information: Geos Not Updated</strong>,
                        variant: 'warning',
                        solid: true
                    })
                } else {
                    this.$bvToast.toast("Cluster saved successfully", {
                        title: <strong>Successful</strong>,
                        variant: 'success',
                        solid: true
                    });
                    this.$emit('close');
                }
            })
        },
        getWorkers() {
            axios.get(`${this.$root.serverUrl}/admin/cluster/workers`, {params: {ip: this.ip}}).then((resp) => {
                this.clusters = resp.data.data;
            }).catch((err) => {
                console.log(`Fetch error: ${err.message}`, err.stack);
                swal.fire({ title: "Error", text: err.message, icon: "error" });
            });
        }
    }
}
</script>

<style scoped>

</style>
