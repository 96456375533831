<template>
    <b-modal
        id="servLogs"
        title="Service Logs"
        modal-class="sizeOverride"
        body-class="p-0 backGround"
        no-close-on-esc
        no-close-on-backdrop
        centered
        size="xl"
        hide-footer
        @close="done"
    >
        <template #modal-title>
            <div class="p-2" style="background: #ffffff;">{{id.ip}} (logs)</div>
        </template>
        <div class="modal-body backGround">
            <div class="row">
                <div class="col-12" style="overflow-y: scroll;max-height: 663px;overflow-x: clip;">
                    <p class="m-0 message" v-for="(str, index) in logs" :key="index"><strong class="labels">message: </strong>{{str}}</p>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import EventSource from "eventsource";

export default {
    name: "cluster-logs",
    data() {
        return {
            logs: [],
            events: null
        }
    },
    props: {
        id: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
    },
    beforeDestroy() {
        if(this.events) this.events.close();
    },
    mounted() {
        this.$bvModal.show("servLogs")
        this.events = new EventSource(
            `${this.$root.serverUrl}/admin/cluster${this.type === 'worker' ? '/worker' : ''}/log/${this.id._id}`,
            { headers: { 'Authorization': "Bearer " +  this.$cookies.get('accessToken')} }
        );
        this.events.onmessage = (event) => {
            try {
                const parsedData = JSON.parse(event.data)

                this.setLogData(parsedData);
                this.$forceUpdate();
            } catch (e) {
                console.log(`eventLogs.catch: ${e.message}`);
            }

        };
    },
    methods: {
        setLogData(data){
            this.logs.push(data);
            if(this.logs.length > 1030) {
                this.logs.shift();
            }
        },
        done() {
            console.log("clossing")
            this.$emit('close');
        }
    }
}
</script>

<style scoped>
.backGround {
    background-color: #044751 !important;
    min-width: 200px;
    min-height: 400px;
}
.message {
    color: #9BC6A7 !important;
}
.labels {
    color: #fdc46b !important;
}
</style>
