<template>
    <div type="text/x-template" id="modal-template">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="container">
                            <div class="row d-flex justify-content-end">
                                <b-icon-x class="mt-3 mr-3 font-weight-bold" style="cursor: pointer;" @click="$emit('close')"></b-icon-x>
                            </div>
                        </div>

                        <div>
                            <div class="modal-header d-flex justify-content-center p-3">
                                <h5 class="m-0"><strong>Add Cluster</strong></h5>
                            </div>

                            <div class="modal-body pl-5 pr-5">
                                <div class="row">

                                    <div class="col-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>IP Address</label>
                                                    <input type="text" v-model="cluster.ip" autocomplete="off" class="form-control required" required/>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Type</label>
                                                    <select required v-model="cluster.type" class="form-control">
                                                        <option value="" selected disabled>-- Type --</option>
                                                        <option value="pi">Pi</option>
                                                        <option value="lxd">LXD</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Status</label>
                                                    <select required v-model="cluster.status" class="form-control">
                                                        <option value="" selected disabled>-- Status --</option>
                                                        <option value="active">Active</option>
                                                        <option value="disable">Disable</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row pb-2">
                                            <div class="col-6">
                                                <b>Host Credentials</b>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Username</label>
                                                    <input type="text" v-model="cluster.credentials.host.username" autocomplete="off" class="form-control required" required/>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Password</label>
                                                    <input type="password" v-model="cluster.credentials.host.password" autocomplete="off" class="form-control required" required/>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-if="cluster.type === 'pi'" class="row pb-2">
                                            <div class="col-6">
                                                <b>Switch Credentials</b>
                                            </div>
                                        </div>
                                        <div v-if="cluster.type === 'pi'" class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Username</label>
                                                    <input type="text" v-model="cluster.credentials.switch.username" autocomplete="off" class="form-control required" required/>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Password</label>
                                                    <input type="password" v-model="cluster.credentials.switch.password" autocomplete="off" class="form-control required" required/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="container">
                                <div class="row">
                                    <div class="col-6 col-md-6 d-flex justify-content-center"><p style="cursor: pointer;" @click="$emit('close')">CANCEL</p></div>
                                    <div class="col-6 col-md-6 d-flex justify-content-center"><p style="cursor: pointer;" @click="save">SAVE</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
// import axios from "axios";

import axios from "axios";

export default {
    name: "edit-cluster",
    data() {
        return {
            cluster: { credentials: {host: {}, switch: {}}}
        }
    },
    mounted() {
        this.cluster = this.item;
    },
    props: {
        item: {
            type: Object,
            required: true,
        }
    },
    components: {},
    methods: {
        save() {
            let action = this.cluster._id ? "put" : "post";
            axios[action](`${this.$root.serverUrl}/admin/cluster`, this.cluster).then((resp) => {
                if ( resp.data && resp.data.error ) {
                    this.$bvToast.toast(resp.data.error.message, {
                        title: <strong>Information: Geos Not Updated</strong>,
                        variant: 'warning',
                        solid: true
                    })
                } else {
                    this.$bvToast.toast("Cluster saved successfully", {
                        title: <strong>Successful</strong>,
                        variant: 'success',
                        solid: true
                    });
                    this.$emit('close');
                }
            })
        }
    }
}
</script>

<style scoped>

.form-control:focus {
    -webkit-box-shadow: 0 0 0 0rem;
    box-shadow: 0 0 0 0rem;
    border-color: #CED4DA;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: table;
    transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    -moz-transition: opacity 0.3s ease;
    -webkit-transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: 600px;
    margin: 0px auto;
    padding: 0px 0px 20px 0px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
    margin-top: 0;
    color: #42b983;
}

.modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-leave-active .modal-container {
    transform: scale(1.1);
    -o-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
}

</style>
